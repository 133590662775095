// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chipDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
    margin-bottom: 12px;
    font-size: 0.75rem;

}

.form > .MuiButtonGroup-root {
    width: auto;
}

.marginalized-elem {
    margin-left: 12px;
    margin-right: 12px;
}

.chipDiv > .dayChip {
    flex-grow: 1;
    text-transform: capitalize;
    margin: 0 0;
    max-width: 100px;
}

.form > .marginalized-elem {
    margin: 0 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/SearchPanel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;;AAEtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,0BAA0B;IAC1B,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".chipDiv {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-between;\n}\n\n.form {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n    margin-top: 16px;\n    margin-bottom: 12px;\n    font-size: 0.75rem;\n\n}\n\n.form > .MuiButtonGroup-root {\n    width: auto;\n}\n\n.marginalized-elem {\n    margin-left: 12px;\n    margin-right: 12px;\n}\n\n.chipDiv > .dayChip {\n    flex-grow: 1;\n    text-transform: capitalize;\n    margin: 0 0;\n    max-width: 100px;\n}\n\n.form > .marginalized-elem {\n    margin: 0 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
