// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlayColumn{
    min-width: 10ch;
}
.innerOverColumn{
    position: relative;
    width: inherit;
}`, "",{"version":3,"sources":["webpack://./src/components/schedule/ScheduleEventColumn.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".overlayColumn{\n    min-width: 10ch;\n}\n.innerOverColumn{\n    position: relative;\n    width: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
