// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#menu li.dayEnabled {
    font-weight: bold;
    background-color: #1976d2;
    color: white;
}

.dayDisabled {
    font-weight: normal;
    color: #1976d2;

    background-color: white;
}

ul#menu {
    list-style: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    box-sizing: border-box;

}

#menu li {
    box-sizing: border-box;
    display: inline;
    padding: 4px 0.5rem;
    border: 1px solid #1976d2;
    font-size: 0.8125rem;
    text-align: center;
    z-index: 2;
    width: 100%;

}
      `, "",{"version":3,"sources":["webpack://./src/components/LeftContent/WeekDay.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,cAAc;;IAEd,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,SAAS;IACT,sBAAsB;;AAE1B;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,mBAAmB;IACnB,yBAAyB;IACzB,oBAAoB;IACpB,kBAAkB;IAClB,UAAU;IACV,WAAW;;AAEf","sourcesContent":["#menu li.dayEnabled {\n    font-weight: bold;\n    background-color: #1976d2;\n    color: white;\n}\n\n.dayDisabled {\n    font-weight: normal;\n    color: #1976d2;\n\n    background-color: white;\n}\n\nul#menu {\n    list-style: none;\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    margin: 0;\n    box-sizing: border-box;\n\n}\n\n#menu li {\n    box-sizing: border-box;\n    display: inline;\n    padding: 4px 0.5rem;\n    border: 1px solid #1976d2;\n    font-size: 0.8125rem;\n    text-align: center;\n    z-index: 2;\n    width: 100%;\n\n}\n      "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
