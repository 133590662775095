export const ADDED_SECTION = 'ADDED_SECTION'
export const ADDED_BLACKOUT = 'ADDED_BLACKOUT'

export const REMOVED_SECTION = 'REMOVED_SECTION'
export const REMOVED_BLACKOUT = 'REMOVED_BLACKOUT'

export const COURSE_SEARCH_COMPLETE = 'COURSE_SEARCH_COMPLETE'

export const HIGHLIGHT_SECTION = 'HIGHLIGHT_SECTION'

export const SELECTED_PLAN = "SELECTED_PLAN"

export const CHANGE_TAB = "CHANGE_TAB"

export const CHANGE_USER = "CHANGE_USER"