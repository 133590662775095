// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}



#header {
    width: 100%;
}

.debugContainer {
    width: 50%;
    height: 1000px;
    border: 4px solid blue;
    padding: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: row;

}

#body {
    display: flex;
    flex-direction: row;
}

#scheduleHalf {
    flex-grow: 1;
    height: 100%;
    overflow-x: scroll;
}

.toolbar {
    padding-right: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;;;AAIA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;IACV,cAAc;IACd,sBAAsB;IACtB,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,mBAAmB;;AAEvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".App {\n  text-align: center;\n}\n\n\n\n#header {\n    width: 100%;\n}\n\n.debugContainer {\n    width: 50%;\n    height: 1000px;\n    border: 4px solid blue;\n    padding: 15px;\n    overflow: hidden;\n    display: flex;\n    flex-direction: row;\n\n}\n\n#body {\n    display: flex;\n    flex-direction: row;\n}\n\n#scheduleHalf {\n    flex-grow: 1;\n    height: 100%;\n    overflow-x: scroll;\n}\n\n.toolbar {\n    padding-right: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
