// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.column{
    flex-basis: 0;
    flex-grow: 1;
}

.scheduleContainer{
    flex-grow: 1;
    border-left: 2px solid gray;
    position: relative;
}
.scheduleContainer * {
    left: 0;
    right: 0;
}
.scheduleContent{
    position: absolute;
    display: flex;
    flex-direction: row;
}
.scheduleOverlay{
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/components/schedule/ScheduleContainer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,2BAA2B;IAC3B,kBAAkB;AACtB;AACA;IACI,OAAO;IACP,QAAQ;AACZ;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".column{\n    flex-basis: 0;\n    flex-grow: 1;\n}\n\n.scheduleContainer{\n    flex-grow: 1;\n    border-left: 2px solid gray;\n    position: relative;\n}\n.scheduleContainer * {\n    left: 0;\n    right: 0;\n}\n.scheduleContent{\n    position: absolute;\n    display: flex;\n    flex-direction: row;\n}\n.scheduleOverlay{\n    height: 100%;\n    position: absolute;\n    display: flex;\n    flex-direction: row;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
