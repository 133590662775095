// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eventSticker{
    min-width: 10ch;
    position: absolute;
    box-sizing: border-box;
    border-right: 4px hidden transparent;
    width: 100%;
    padding: 2px;
    border-radius: 5px;
}
.stickerTitle{
    margin: 0;
    max-height: 16px;
    font-size: 12px;
}

.stickerSubtitle {
    max-height: 13px;
    font-size: 11px;
    text-align: left;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-all;
    text-overflow: ellipsis;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: normal;
    margin: 0;
}

.campus {
    max-height: 13px;
    font-size: 8px;
    overflow-wrap: break-word;
    text-decoration: underline;
    word-break: break-all;
    text-overflow: ellipsis;
    margin: 0;
}

.darkmodetext {
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/schedule/ScheduleEvent.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,oCAAoC;IACpC,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,SAAS;IACT,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,qBAAqB;IACrB,qBAAqB;IACrB,uBAAuB;IACvB,uDAAuD;IACvD,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,0BAA0B;IAC1B,qBAAqB;IACrB,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".eventSticker{\n    min-width: 10ch;\n    position: absolute;\n    box-sizing: border-box;\n    border-right: 4px hidden transparent;\n    width: 100%;\n    padding: 2px;\n    border-radius: 5px;\n}\n.stickerTitle{\n    margin: 0;\n    max-height: 16px;\n    font-size: 12px;\n}\n\n.stickerSubtitle {\n    max-height: 13px;\n    font-size: 11px;\n    text-align: left;\n    overflow-wrap: break-word;\n    white-space: pre-wrap;\n    word-break: break-all;\n    text-overflow: ellipsis;\n    font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n    font-weight: normal;\n    margin: 0;\n}\n\n.campus {\n    max-height: 13px;\n    font-size: 8px;\n    overflow-wrap: break-word;\n    text-decoration: underline;\n    word-break: break-all;\n    text-overflow: ellipsis;\n    margin: 0;\n}\n\n.darkmodetext {\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
