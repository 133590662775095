// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emptyClass {
    color: red;
}

.halfContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.contentHalf {
    width: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.contentHalf p {
    font-size: 0.875rem;
    margin: 2px 0;
    display: inline-block;
}

.contentHalf .clipboardText p {
    font-size: 0.875rem;
    margin: 2px 0;
    display: inline-block;
    border-bottom: 1px solid transparent;
    transition: all .3s;
    color: black;
}

@keyframes glow {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: #9c27b088;
    }
    100% {
        background-color: transparent;
    }
}

.active {
    animation-name: glow;
    animation-duration: 0.7s;
    animation-direction: normal;
}

.activeWeekDaysPilbox {
    width: 100%;
}

.activeWeekDaysPilbox #menu {
    padding: 0 16px 0 0;
}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/CourseListItem.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,qBAAqB;IACrB,oCAAoC;IACpC,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI;QACI,6BAA6B;IACjC;IACA;QACI,2BAA2B;IAC/B;IACA;QACI,6BAA6B;IACjC;AACJ;;AAEA;IACI,oBAAoB;IACpB,wBAAwB;IACxB,2BAA2B;AAC/B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".emptyClass {\n    color: red;\n}\n\n.halfContainer {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    flex-wrap: wrap;\n}\n\n.contentHalf {\n    width: 50%;\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n}\n\n.contentHalf p {\n    font-size: 0.875rem;\n    margin: 2px 0;\n    display: inline-block;\n}\n\n.contentHalf .clipboardText p {\n    font-size: 0.875rem;\n    margin: 2px 0;\n    display: inline-block;\n    border-bottom: 1px solid transparent;\n    transition: all .3s;\n    color: black;\n}\n\n@keyframes glow {\n    0% {\n        background-color: transparent;\n    }\n    50% {\n        background-color: #9c27b088;\n    }\n    100% {\n        background-color: transparent;\n    }\n}\n\n.active {\n    animation-name: glow;\n    animation-duration: 0.7s;\n    animation-direction: normal;\n}\n\n.activeWeekDaysPilbox {\n    width: 100%;\n}\n\n.activeWeekDaysPilbox #menu {\n    padding: 0 16px 0 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
